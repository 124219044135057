<template>
    <div class="bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="title">404 Not found</h1>
                <p class="subtitle">This page doesn't exist.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '404',
}
</script>

<style scoped>
div.bg {
    background: url('../../assets/images/consumable-bg.svg') no-repeat center fixed;
    background-size: cover;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: -1;
}

h1.title {
    text-transform: uppercase;
    font-family: Montserrat-Bold;
    color: #F2F2F3;
    margin-bottom: 20px;
    font-size: 5vw;
}

p.subtitle {
    font-family: Montserrat-Light;
    color: #F2F2F3;
    padding: 0 10%;
    font-size: 2vw;
}

img.logo {
    height: 100px;
    margin-bottom: 25px;
}

.container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 576px) {
    p.subtitle {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 768px) {
    p.subtitle {
        font-size: 4vw;
    }
    h1.title {
        font-size: 7vw;
    }
}
</style>